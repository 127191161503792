@font-face {
  font-family: 'Daypbl';
  src: url('./assets/fonts/DAYPBL__.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Itchy';
  src: url('./assets/fonts/Itchy_Handwriting/Itchy_handwriting.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-gap: 10px;
  justify-content: center;
  margin: 20px 0;
}
.hole {
  width: 100px;
  height: 100px;
  background-color: #8B4513;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
.mole, .bomb, .champagne {
  font-size: 40px;
}
body{
  background-image: url("../public/assets/fondo.png"); 
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: auto;
}
